import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { selectMx, selectName } from "../../../store/selectors";
import "./RightHeader.css";

export type RightHeaderProps = {
  onInfoClick: () => void;
};

export const RightHeader = ({ onInfoClick }: RightHeaderProps) => {
  const name = useSelector(selectName);
  const mx = useSelector(selectMx);

  return (
    <div className="rightHeader">
      <div className="rightHeader_logo">Diagram Hacker{mx && `: ${name}`}</div>
      <Button
        icon="pi pi-question-circle"
        className="p-button-text p-button-lg rightHeader_icon"
        onClick={onInfoClick}
        style={{ fontSize: "1.8rem" }}
        tooltip="О программе"
        tooltipOptions={{ position: "left" }}
      />
    </div>
  );
};
