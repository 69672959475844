import { ATTRS_FORMATS, ATTRS_SUBTITLE, TAttrs } from "../store/types";

export const renderAttrs = (mx: HTMLElement) => {
  const attrsSet = new Set<string>();
  mx.querySelectorAll("object").forEach((el: HTMLObjectElement) => {
    for (const attr of el.attributes) {
      attrsSet.add(attr.name);
    }
  });

  const attrs = Array.from(attrsSet).map((attr: string, index: number) => {
    const result: TAttrs = {
      name: attr,
      show: true,
      title: attr === "label" ? ATTRS_FORMATS.title : ATTRS_FORMATS.text,
      subtitle: ATTRS_SUBTITLE.no,
      text: "",
      empty: true,
    };

    return result;
  });

  return attrs;
};
