const readFile = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (reader.result) {
          resolve(reader.result as string);
        }
        reject("error loading file");
      });
      reader.readAsText(file);
    } else {
      reject("not a file");
    }
  });
};

export default readFile;
