import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAttrs,
  selectExpanded,
  selectMd,
  selectMx,
  selectName,
  selectTextSort,
} from "../../../store/selectors";
import { Dropdown } from "primereact/dropdown";
import { SORT_ITEMS } from "../../../store/types";
import { renderMd } from "../../../lib/renderMd";

import styles from "./PanelText.module.css";

/**
 * Панель с текстовым представлением
 */
export const PanelText = () => {
  const dispatch = useDispatch();
  const expanded = useSelector(selectExpanded);

  const md = useSelector(selectMd);
  const name = useSelector(selectName);
  const mx = useSelector(selectMx);
  const attrs = useSelector(selectAttrs);
  const textSort = useSelector(selectTextSort);

  useEffect(() => {
    if (mx && attrs) {
      dispatch({ type: "SET_MD", payload: renderMd(mx, attrs, textSort) });
    }
  }, [mx, attrs, dispatch, textSort]);

  /**
   * обработчик сохранения Markdown
   */
  const handleSaveMd = useCallback(() => {
    if (md) {
      const uriContent =
        `data:application/octet-stream,` + encodeURIComponent(md);

      const downloadLink = document.createElement("a");
      downloadLink.href = uriContent;
      downloadLink.download = `${name}.md`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [md, name]);

  return (
    <div className={styles.panel}>
      <Toolbar
        left={
          <>
            <Dropdown
              value={textSort}
              options={SORT_ITEMS}
              onChange={(e) => {
                dispatch({
                  type: "SET_TEXTSORT",
                  payload: e.value,
                });
              }}
              tooltip="Выбрать алгоритм обхода диаграммы"
              tooltipOptions={{ position: "bottom" }}
              className="mr-2"
            />
            <Button
              icon="pi pi-download"
              className="p-button-outlined"
              onClick={handleSaveMd}
              tooltip="Сохранить Markdown"
              tooltipOptions={{ position: "bottom" }}
              disabled={!md}
            />
          </>
        }
        right={
          <>
            {expanded === 0 && (
              <Button
                icon="pi pi-window-maximize"
                className="p-button-text"
                onClick={() => dispatch({ type: "SET_EXPANDED", payload: 3 })}
                tooltip="Развернуть"
                tooltipOptions={{ position: "left" }}
              />
            )}
            {expanded === 3 && (
              <Button
                icon="pi pi-window-minimize"
                className="p-button-text"
                onClick={() => dispatch({ type: "SET_EXPANDED", payload: 0 })}
                tooltip="Свернуть"
                tooltipOptions={{ position: "left" }}
              />
            )}
          </>
        }
      />
      <div className={styles.content}>
        {md && (
          <InputTextarea value={md} autoResize style={{ width: "100%" }} />
        )}
      </div>
    </div>
  );
};
