import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import {
  Column,
  ColumnBodyOptions,
  ColumnEditorOptions,
} from "primereact/column";
import { DataTable, DataTableRowReorderParams } from "primereact/datatable";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton, RadioButtonChangeParams } from "primereact/radiobutton";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderAttrs } from "../../../../lib/renderAttrs";
import { renderMd } from "../../../../lib/renderMd";
import { renderTable } from "../../../../lib/renderTable";
import { selectAttrs, selectMx } from "../../../../store/selectors";
import { ATTRS_FORMATS, TAttrs } from "../../../../store/types";

export const TextOptions = () => {
  /**
   * Attribute nodes
   */
  const attrs = useSelector(selectAttrs);
  const mx = useSelector(selectMx);

  const dispatch = useDispatch();

  useEffect(() => {
    if (attrs && mx) {
      dispatch({
        type: "SET_MD",
        payload: renderMd(mx, attrs),
      });

      dispatch({
        type: "SET_TABLE",
        payload: renderTable(mx, attrs),
      });
    }
  }, [attrs, mx, dispatch]);

  /**
   * Обработка сортировки
   */
  const handleRowReorder = (e: DataTableRowReorderParams) => {
    dispatch({
      type: "SET_ATTRS",
      payload: e.value,
    });
  };

  /**
   * Обработка столбца "Показать"
   */
  const showTemplate = (rowData: TAttrs, options: ColumnBodyOptions) => {
    return (
      <Checkbox
        onChange={(e: CheckboxChangeParams) => {
          const newAttrs = [...attrs];
          newAttrs[options.rowIndex].show = e.checked;
          dispatch({ type: "SET_ATTRS", payload: newAttrs });
        }}
        checked={rowData.show}
      ></Checkbox>
    );
  };

  /**
   * Обработка столбца "Формат"
   */
  const titleTemplate = (rowData: TAttrs, options: ColumnBodyOptions) => {
    return (
      <RadioButton
        inputId={`title${options.rowIndex}`}
        name="title"
        value={rowData.title}
        onChange={(e: RadioButtonChangeParams) => {
          const newAttrs = [...attrs];
          newAttrs.forEach((_attr, index) => {
            newAttrs[index].title = ATTRS_FORMATS.text;
          });
          newAttrs[options.rowIndex].title = ATTRS_FORMATS.title;
          dispatch({ type: "SET_ATTRS", payload: newAttrs });
        }}
        checked={rowData.title === ATTRS_FORMATS.title}
      />
    );
  };

  /**
   * Обработка слолбца "Заголовок"
   */
  const subtitleTemplate = (rowData: TAttrs, options: ColumnBodyOptions) => {
    return rowData.title === "text" ? (
      <Dropdown
        value={rowData.subtitle}
        options={[
          { label: "Указать", value: "select" },
          { label: "Нет", value: "no" },
          { label: "Из текста", value: "from_text" },
        ]}
        onChange={(e: DropdownChangeParams) => {
          const newAttrs = [...attrs];
          newAttrs[options.rowIndex].subtitle = e.value;
          dispatch({ type: "SET_ATTRS", payload: newAttrs });
        }}
      />
    ) : (
      <span>Не применимо</span>
    );
  };

  /**
   * Обработка столбца "Текст заголовка"
   */
  const textTemplate = (rowData: TAttrs, options: ColumnBodyOptions) => {
    return rowData.subtitle === "select" &&
      rowData.title !== ATTRS_FORMATS.title ? (
      <InputText
        value={rowData.text}
        onChange={(e) => {
          const newAttrs = [...attrs];
          newAttrs[options.rowIndex].text = e.target.value;
          dispatch({ type: "SET_ATTRS", payload: newAttrs });
        }}
      />
    ) : (
      <span>Не применимо</span>
    );
  };

  /**
   * Обработка столбца "Пустые"
   */
  const emptyTemplate = (rowData: TAttrs, options: ColumnBodyOptions) => (
    <Checkbox
      onChange={(e: CheckboxChangeParams) => {
        const newAttrs = [...attrs];
        newAttrs[options.rowIndex].empty = e.checked;
        dispatch({ type: "SET_ATTRS", payload: newAttrs });
      }}
      checked={rowData.empty}
    ></Checkbox>
  );

  /**
   * Удаление атрибута
   */
  const deleteTemplate = (rowData: TAttrs, options: ColumnBodyOptions) => (
    <Button
      icon="pi pi-trash"
      className="p-button-outlined p-button-danger"
      onClick={createHandleDeleteAttr(rowData.name)}
      tooltip="Удалить атрибут"
      tooltipOptions={{ position: "bottom" }}
      disabled={rowData.name === "id" || rowData.name === "label"}
    />
  );

  /* обработчик удаления атрибута */
  const createHandleDeleteAttr = useCallback(
    (attr: string) => () => {
      mx.querySelectorAll("object").forEach((el: Element) => {
        el.removeAttribute(attr);
        el.removeAttribute("xmlns");
      });

      dispatch({ type: "SET_MX", payload: mx });
      dispatch({
        type: "SET_ATTRS",
        payload: renderAttrs(mx),
      });
    },
    [mx, dispatch]
  );

  /**
   * Переименовываем атрибут
   */
  const onCellEditComplete = useCallback(
    (e: any) => {
      const { value, newValue, originalEvent } = e;

      // заменяем имя атрибута только если новое значение не пустое и не равно старому
      if (newValue.trim().length > 0 && value !== newValue) {
        // заменяет старый атрибут на новый во всех узлах
        mx.querySelectorAll("object").forEach((el: Element) => {
          const attrValue = el.getAttribute(value) ?? "";
          el.setAttribute(newValue, attrValue);
          el.removeAttribute(value);
          el.removeAttribute("xmlns");
        });

        dispatch({ type: "SET_MX", payload: mx });
        dispatch({
          type: "SET_ATTRS",
          payload: renderAttrs(mx),
        });
      } else {
        originalEvent.preventDefault();
      }
    },
    [mx, dispatch]
  );

  /* редактор для поля атрибута */
  const textEditor = (options: ColumnEditorOptions) => {
    // поля id и label нельзя редактировать
    if (options.value === "id" || options.value === "label")
      return options.value;

    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback?.(e.target.value)}
      />
    );
  };

  /* рендер */
  return (
    <DataTable value={attrs} reorderableRows onRowReorder={handleRowReorder}>
      <Column rowReorder style={{ width: "3em" }} />
      <Column
        field="name"
        header="Атрибут"
        editor={(options) => textEditor(options)}
        onCellEditComplete={onCellEditComplete}
      />
      <Column field="show" header="Выводить" body={showTemplate} />
      <Column field="title" header="Заголовок" body={titleTemplate} />
      <Column field="subtitle" header="Подзаголовок" body={subtitleTemplate} />
      <Column field="text" header="Текст подзаголовка" body={textTemplate} />
      <Column field="empty" header="Пустые" body={emptyTemplate} />
      <Column body={deleteTemplate} />
    </DataTable>
  );
};
