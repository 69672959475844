import { TAttrs, TNode, TSort } from "../store/types";
import { BooleanFilter } from "./booleanfilter";
import { getNodesByIds } from "./getNodesById";

/**
 * рендер mx в массив
 * @param mx DOM диаграммы
 * @param attrs параметры атрибутов
 * @param sortType сортировка в глубину/в ширину
 */
export const renderTable = (
  mx: HTMLElement,
  attrs: TAttrs[],
  sortType: TSort = "depth"
) => {
  let render: Array<Record<string, string>> = [];

  const diagrams = mx.getElementsByTagName("diagram");

  Array.from(diagrams).forEach((diagram: Element) => {
    const graphModels = diagram.getElementsByTagName("mxGraphModel");

    Array.from(graphModels).forEach((graphModel: Element, key: number) => {
      const root = graphModel.getElementsByTagName("root")[0];
      const vertexes = root.querySelectorAll("mxCell[vertex='1']");

      const rawNodes: TNode[] = Array.from(vertexes)
        .map((vertex: Element, i: number) => {
          const vertexId = vertexes[i].parentElement?.getAttribute("id");

          if (!vertexId) return undefined;

          const vertexSources = Array.from(
            root.querySelectorAll(`mxCell[edge="1"][source="${vertexId}"]`)
          );
          const vertexTargets = Array.from(
            root.querySelectorAll(`mxCell[edge="1"][target="${vertexId}"]`)
          );

          return {
            id: vertexId,
            sourceIds: vertexSources
              .map((source: Element) =>
                source.parentElement?.getAttribute("id")
              )
              .filter(BooleanFilter),
            targetIds: vertexTargets
              .map((source: Element) =>
                source.parentElement?.getAttribute("id")
              )
              .filter(BooleanFilter),
            visited: false,
          };
        })
        .filter(BooleanFilter);

      // filter isolated nodes
      const filteredNodes = rawNodes
        .filter(
          (node) => node?.sourceIds.length !== 0 || node?.targetIds.length !== 0
        )
        .map((node) => ({
          ...node,
          visited: false,
        }));

      // find start nodes
      const startNodes = filteredNodes.filter(
        (node) => node.targetIds?.length === 0
      );

      let nodes: TNode[] = [];

      if (sortType === "breadth") {
        /**
         * Обход узлов "в ширину"
         */
        const resultNodes: TNode[] = [];

        startNodes.forEach((startNode) => {
          resultNodes.push({ ...startNode, visited: true });

          const currNodes = getNodesByIds(filteredNodes, startNode.sourceIds);

          while (currNodes.some((n) => !n?.visited)) {
            currNodes.forEach((currNode) => {
              if (!currNode) return;
              !resultNodes.includes(currNode) && resultNodes.push(currNode);
              currNode.visited = true;

              const addToCurrNodes = getNodesByIds(
                filteredNodes,
                currNode?.sourceIds || []
              ).filter((n) => !n?.visited);
              currNodes.push(...addToCurrNodes);
            });
          }
        });

        nodes = resultNodes;
      } else {
        /**
         * Обход узлов "в грубину"
         */
        const deepNodes: TNode[] = [];

        const walkSourcesIds = (ids: string[], order: string) => {
          const currNodes = getNodesByIds(filteredNodes, ids);

          currNodes.forEach((n, index) => {
            if (n.visited) return;

            const orderValue = `${order}.${String(index + 1).padStart(3, "0")}`;

            deepNodes.push({
              ...n,
              visited: true,
              order: orderValue,
            });
            filteredNodes.filter((fN) => fN.id === n.id)[0].visited = true;
            walkSourcesIds(n.sourceIds, orderValue);
          });
        };

        startNodes.forEach((startNode, index) => {
          deepNodes.push({
            ...startNode,
            visited: true,
            order: `${String(index + 1).padStart(3, "0")}`,
          });
          startNode.visited = true;

          walkSourcesIds(
            startNode.sourceIds,
            `${String(index + 1).padStart(3, "0")}`
          );
        });

        nodes = deepNodes;
      }

      const newNodes = nodes.map((n) => {
        const obj = root.querySelector(`object[id="${n.id}"]`);

        const result: Record<string, string> = {
          id: n.id,
          order: n.order ?? "",
        };

        Array.from(attrs).forEach((a: TAttrs) => {
          result[a.name] = obj?.getAttribute(a.name) || "";
        });

        return result;
      });

      render = [...render, ...newNodes];
    });
  });

  return render;
};
