import { TNode } from "../store/types";
import { BooleanFilter } from "./booleanfilter";

/**
 * получаем массив узлов по id
 * @param nodes исходные узлы
 * @param nodeIds массив id
 * @returns массив отфильтрованных узлов
 */
export const getNodesByIds = (nodes: TNode[], nodeIds: string[]) => {
  // console.log("getNodesByIds", nodes, nodeIds);
  return nodeIds
    .map((nodeId) => nodes.find((n) => n.targetIds.includes(nodeId)))
    .filter(BooleanFilter);
};
