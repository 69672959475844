import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { useDispatch, useSelector } from "react-redux";
import { selectExpanded, selectMd, selectMx } from "../../../store/selectors";
import styles from "./PanelDiagram.module.css";
import { useEffect } from "react";
import { renderMxgraph } from "../../../lib/renderMxgraph";

import mx from "../../../mxgraph";
import type { mxGraph, mxGraphModel } from "mxgraph";
import { setShape } from "./setShape";

export const PanelDiagram = () => {
  const dispatch = useDispatch();
  const expanded = useSelector(selectExpanded);

  const mxData = useSelector(selectMx);
  const md = useSelector(selectMd);

  useEffect(() => {
    const container = document.getElementById("diagram");

    if (mxData && md && container) {
      container.innerHTML = "";

      if (mx.mxClient.isBrowserSupported()) {
        console.log("Yes! Yes!");
      }

      const graph: mxGraph = new mx.mxGraph(container);
      const model: mxGraphModel = graph.getModel();

      // Устанавливаем дефолтные стили
      graph.getStylesheet().putDefaultVertexStyle({
        ...graph.getStylesheet().getDefaultVertexStyle(),
        // [mx.mxConstants.STYLE_SHAPE]: mx.mxConstants.SHAPE_ELLIPSE,
        [mx.mxConstants.STYLE_FILLCOLOR]: "#fff",
        [mx.mxConstants.STYLE_STROKECOLOR]: "#000",
        [mx.mxConstants.STYLE_FONTCOLOR]: "#000",
      });

      graph.getStylesheet().putDefaultEdgeStyle({
        ...graph.getStylesheet().getDefaultEdgeStyle(),
        [mx.mxConstants.STYLE_STROKECOLOR]: "#000",
        [mx.mxConstants.STYLE_EDGE]: mx.mxEdgeStyle.EntityRelation,
        [mx.mxConstants.STYLE_ORTHOGONAL]: false,
        // [mx.mxConstants.STYLE_CURVED]: 1,
        // [mx.mxConstants.STYLE_SOURCE_PERIMETER_SPACING]: 10,
        // [mx.mxConstants.STYLE_TARGET_PERIMETER_SPACING]: 10,
      });

      mx.mxEvent.disableContextMenu(container);
      graph.setPanning(true);
      graph.setHtmlLabels(true);

      const parent = graph.getDefaultParent();

      const { nodes, links } = renderMxgraph(mxData);

      // console.log("nodes", nodes);
      // console.log("links", links);

      const nodeIds = new Map();

      model.beginUpdate();

      try {
        nodes.forEach(({ id, content, x, y, w, h, style = "" }) => {
          const node = graph.insertVertex(
            parent,
            null,
            content,
            x,
            y,
            w,
            h,
            String(style)
          );

          setShape(node, String(style));

          nodeIds.set(id, node);
        });

        links.forEach(({ input, output, style }) => {
          graph.insertEdge(
            parent,
            null,
            "",
            nodeIds.get(input),
            nodeIds.get(output),
            String(style)
          );
        });
      } finally {
        model.endUpdate();
      }

      graph.fit(10);

      const zoomInButton = document.getElementById("zoomInButton");
      const zoomOutButton = document.getElementById("zoomOutButton");
      const resetButton = document.getElementById("resetButton");

      const handleZoomIn = () => graph.zoomIn();
      const handleZoomOut = () => graph.zoomOut();
      const handleReset = () => {
        graph.fit(10);
      };

      zoomInButton?.addEventListener("click", handleZoomIn);
      zoomOutButton?.addEventListener("click", handleZoomOut);
      resetButton?.addEventListener("click", handleReset);

      return () => {
        zoomInButton?.removeEventListener("click", handleZoomIn);
        zoomOutButton?.removeEventListener("click", handleZoomOut);
        resetButton?.removeEventListener("click", handleReset);
      };
    }
  }, [mxData, md]);

  return (
    <>
      <Toolbar
        left={
          <>
            <Button
              icon="pi pi-search-plus"
              className="p-button-outlined mr-2"
              id="zoomInButton"
            />
            <Button
              icon="pi pi-search-minus"
              className="p-button-outlined mr-2"
              id="zoomOutButton"
            />
            <Button
              icon="pi pi-replay"
              className="p-button-outlined"
              id="resetButton"
            />
          </>
        }
        right={
          <>
            {expanded === 0 && (
              <Button
                icon="pi pi-window-maximize"
                className="p-button-text"
                onClick={() => dispatch({ type: "SET_EXPANDED", payload: 1 })}
              />
            )}
            {expanded === 1 && (
              <Button
                icon="pi pi-window-minimize"
                className="p-button-text"
                onClick={() => dispatch({ type: "SET_EXPANDED", payload: 0 })}
              />
            )}
          </>
        }
      />
      <div id="diagram" className={styles.diagram} />
    </>
  );
};
