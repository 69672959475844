import { mxCell } from "mxgraph";
import mx from "../../../mxgraph";

const SHAPES = [
  mx.mxConstants.SHAPE_RECTANGLE,
  mx.mxConstants.SHAPE_ELLIPSE,
  mx.mxConstants.SHAPE_DOUBLE_ELLIPSE,
  mx.mxConstants.SHAPE_RHOMBUS,
  mx.mxConstants.SHAPE_LINE,
  mx.mxConstants.SHAPE_IMAGE,
  mx.mxConstants.SHAPE_ARROW,
  mx.mxConstants.SHAPE_ARROW_CONNECTOR,
  mx.mxConstants.SHAPE_LABEL,
  mx.mxConstants.SHAPE_CYLINDER,
  mx.mxConstants.SHAPE_SWIMLANE,
  mx.mxConstants.SHAPE_CONNECTOR,
  mx.mxConstants.SHAPE_ACTOR,
  mx.mxConstants.SHAPE_CLOUD,
  mx.mxConstants.SHAPE_TRIANGLE,
  mx.mxConstants.SHAPE_HEXAGON,
  "parallelogram",
  // "note",
];

export const setShape = (node: mxCell, style: string) => {
  SHAPES.forEach((shape) => {
    if (style.includes(shape)) {
      node.setStyle("shape=" + shape);
    }
  });
};
