import { SelectItemOptionsType } from "primereact/selectitem";

/**
 * стор
 */
export type TStore = {
  mx: any; // DOM диаграммы
  expanded: number; // номер развернутой панели
  md: string; // текстовый рендер диаграммы
  attrs: TAttrs[]; // настройки атрибутов для текстового рендера
  name: string; // имя загруженного файла
  table: TNode[];
  textSort: TSort; // тип сортировки для текстового рендера
  tableSort: TSort; // тип сортировки для табличного рендера
};

/**
 * узел диаграммы
 */
export type TNode = {
  id: string;
  sourceIds: string[];
  targetIds: string[];
  visited: boolean;
  level?: number;
  order?: string;
};

/**
 * форматы атрибута
 */
export enum ATTRS_FORMATS {
  title = "title",
  text = "text",
}

/**
 * источник атрибута
 */
export enum ATTRS_SUBTITLE {
  select = "select",
  no = "no",
  from_text = "from_text",
}

/**
 * настройки для рендера атрибута
 */
export type TAttrs = {
  name: string;
  show: boolean;
  title: ATTRS_FORMATS;
  subtitle: ATTRS_SUBTITLE;
  text: string;
  empty: boolean;
};

export type TSort = "depth" | "breadth";

export const SORT_ITEMS: SelectItemOptionsType = [
  { label: "В глубину", value: "depth" }, // поиск в глубину
  { label: "В ширину", value: "breadth" }, // поиск в ширину
];
