import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAttrs,
  selectExpanded,
  selectMx,
  selectTable,
  selectTableSort,
  selectTextSort,
} from "../../../store/selectors";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column, ColumnEditorOptions } from "primereact/column";
import { renderMd } from "../../../lib/renderMd";
import { renderTable } from "../../../lib/renderTable";
import {
  ATTRS_FORMATS,
  ATTRS_SUBTITLE,
  SORT_ITEMS,
} from "../../../store/types";
import { Dropdown } from "primereact/dropdown";
import { TextOptions } from "./TextOptions/TextOptions";
import { InputTextarea } from "primereact/inputtextarea";

export const PanelTable = () => {
  const dispatch = useDispatch();
  const expanded = useSelector(selectExpanded);

  const mx = useSelector(selectMx);
  const attrs = useSelector(selectAttrs);
  const table = useSelector(selectTable);
  const tableSort = useSelector(selectTableSort);
  const textSort = useSelector(selectTextSort);

  useEffect(() => {
    if (mx && attrs) {
      dispatch({ type: "SET_MD", payload: renderMd(mx, attrs, textSort) });
      dispatch({
        type: "SET_TABLE",
        payload: renderTable(mx, attrs, tableSort),
      });
    }
  }, [mx, attrs, dispatch, textSort, tableSort]);

  /**
   * Dialog for add columns
   */
  const [dialogColumns, setDialogColumns] = useState(false);

  const handleShowDialogColumns = useCallback(() => {
    setDialogColumns(true);
  }, []);

  const handleHideDialogColumns = useCallback(() => {
    setDialogColumns(false);
  }, []);

  /**
   * Dialog for options
   */
  const [dialogOptions, setDialogOptions] = useState(false);

  const handleShowDialogOptions = useCallback(() => {
    setDialogOptions(true);
  }, []);

  const handleHideDialogOptions = useCallback(() => {
    setDialogOptions(false);
  }, []);

  /**
   * Добавить атрибуты
   */
  const [newAttrs, setNewAttrs] = useState("");

  const handleAddAttrs = () => {
    if (!mx || !newAttrs) return;
    const rawAttrs = newAttrs.split(",");
    mx.querySelectorAll("object").forEach((el: HTMLElement) => {
      rawAttrs?.forEach((attr) => {
        el.setAttribute(attr, " ");
      });
    });
    dispatch({ type: "SET_MX", payload: mx });
    dispatch({
      type: "SET_ATTRS",
      payload: [
        ...attrs,
        ...rawAttrs.map((attr) => ({
          name: attr,
          show: true,
          title: ATTRS_FORMATS.text,
          subtitle: ATTRS_SUBTITLE.no,
          text: "",
          empty: true,
        })),
      ],
    });
    setNewAttrs("");
    handleHideDialogColumns();
  };

  /**
   * Добавить сортировку
   */
  // console.log("attrs=", attrs);

  const handleAddOrderAttr = useCallback(() => {
    if (!mx) return;
    if (attrs.map((a) => a.name).includes("order")) {
      // order уже есть
    } else {
      // добавляем атрибут order
      mx.querySelectorAll("object").forEach((el: HTMLElement) => {
        const elId = el.getAttribute("id");
        const order = table.filter((n) => n.id === elId)[0];
        if (order) {
          el.setAttribute("order", order.order ?? "");
        }
      });
      dispatch({ type: "SET_MX", payload: mx });
      dispatch({
        type: "SET_ATTRS",
        payload: [
          ...attrs,
          {
            name: "order",
            show: true,
            title: ATTRS_FORMATS.text,
            subtitle: ATTRS_SUBTITLE.no,
            text: "",
            empty: true,
          },
        ],
      });
    }
  }, [attrs, mx, table, dispatch]);

  /**
   *
   */
  const [selectedNode, setSelectedNode] = useState();

  const textEditor = (options: ColumnEditorOptions) => {
    return (
      <InputTextarea
        type="text"
        autoResize
        value={options.value}
        onChange={(e) => options.editorCallback?.(e.target.value)}
        style={{ width: "100%" }}
      />
    );
  };

  const onCellEditComplete = useCallback(
    (e: any) => {
      const { rowData, newValue, field, originalEvent } = e;

      if (newValue.trim().length > 0) {
        const obj = mx.querySelector(`object[id="${rowData.id}"]`);
        obj?.setAttribute(field, newValue);
        dispatch({ type: "SET_MX", payload: mx });
        // console.log("mx", mx);
        dispatch({ type: "SET_ATTRS", payload: [...attrs] });
      } else {
        originalEvent.preventDefault();
      }
    },
    [mx, dispatch, attrs]
  );

  return (
    <>
      <Toolbar
        left={
          <>
            <Button
              icon="pi pi-sliders-h"
              className="p-button-outlined mr-2"
              onClick={handleShowDialogOptions}
              tooltip="Параметры"
              tooltipOptions={{ position: "bottom" }}
              disabled={!mx}
            />
            <Button
              icon="pi pi-plus-circle"
              className="p-button-outlined mr-2"
              onClick={handleShowDialogColumns}
              tooltip="Добавить атрибуты"
              tooltipOptions={{ position: "bottom" }}
              disabled={!mx}
            />
            <Button
              icon="pi pi-sort-alt"
              className="p-button-outlined mr-2"
              onClick={handleAddOrderAttr}
              tooltip="Добавить сортировку"
              tooltipOptions={{ position: "bottom" }}
              disabled={!mx}
            />
            <Dropdown
              value={tableSort}
              options={SORT_ITEMS}
              onChange={(e) =>
                dispatch({
                  type: "SET_TABLESORT",
                  payload: e.value,
                })
              }
              tooltip="Выбрать алгоритм обхода диаграммы"
              tooltipOptions={{ position: "bottom" }}
            />
          </>
        }
        right={
          <>
            {expanded === 0 && (
              <Button
                icon="pi pi-window-maximize"
                className="p-button-text"
                onClick={() => dispatch({ type: "SET_EXPANDED", payload: 2 })}
              />
            )}
            {expanded === 2 && (
              <Button
                icon="pi pi-window-minimize"
                className="p-button-text"
                onClick={() => dispatch({ type: "SET_EXPANDED", payload: 0 })}
              />
            )}
          </>
        }
      />

      {mx && (
        <DataTable
          value={table}
          scrollable
          scrollHeight="flex"
          selectionMode="single"
          selection={selectedNode}
          onSelectionChange={(e) => setSelectedNode(e.value)}
          dataKey="id"
          style={{ height: "calc(100% - 62px)" }}
        >
          {attrs.map((a) => (
            <Column
              key={a.name}
              field={a.name}
              header={a.name}
              editor={(options) => textEditor(options)}
              onCellEditComplete={onCellEditComplete}
              style={{
                wordBreak: "break-word",
                width: `${100 / attrs.length}%`,
                minWidth: 260,
              }}
            />
          ))}
        </DataTable>
      )}

      <Dialog
        header="Добавить атрибуты"
        visible={dialogColumns}
        modal
        onHide={handleHideDialogColumns}
        style={{ maxWidth: "800px" }}
      >
        <p>
          <InputText
            value={newAttrs}
            onChange={(e) => setNewAttrs(e.target.value)}
          />
        </p>
        <p>
          <Button onClick={handleAddAttrs} disabled={newAttrs.length === 0}>
            Добавить атрибуты
          </Button>
        </p>
        <p>Укажите атрибуты через запятую</p>
        <p>
          Атрибут <code>order</code> системный и служит для явного указания
          порядка вывода в MD-документе.
        </p>
      </Dialog>

      <Dialog
        header="Настройки"
        visible={dialogOptions}
        modal
        onHide={handleHideDialogOptions}
        style={{ maxWidth: "80vw" }}
      >
        <TextOptions />
      </Dialog>
    </>
  );
};
