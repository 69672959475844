/**
 * Если у ноды нет родителя object,
 * то оборачиваем ноду в него
 * @param edge
 */

export const coverNode = (edge: Element) => {
  const edgeParent = edge.parentElement;
  if (edgeParent) {
    const isParentObject = edgeParent.nodeName === "object";
    if (!isParentObject) {
      const objectElement = document.createElement("object");
      objectElement.setAttribute("id", edge.getAttribute("id") ?? "");
      objectElement.setAttribute("label", edge.getAttribute("value") ?? "");
      const edgeElement = edge.cloneNode(true) as Element;
      edgeElement.removeAttribute("id");
      edgeElement.removeAttribute("value");

      objectElement.appendChild(edgeElement);

      edge.replaceWith(objectElement);
    }
  }
};
