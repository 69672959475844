import { coverNode } from "./covernode";

/**
 * Render for Beautiful React Diagram
 * https://github.com/antonioru/beautiful-react-diagrams
 *
 * @param mx
 * @returns nodes and links
 */
export const renderMxgraph = (mx: HTMLElement) => {
  const edges = mx.querySelectorAll("mxCell[edge='1']");
  const vertexes = mx.querySelectorAll("mxCell[vertex='1']");

  edges.forEach(coverNode);
  vertexes.forEach(coverNode);

  // console.log("vertexes", vertexes);
  // console.log("edges", edges);

  const nodes = Array.from(vertexes).map((n: Element) => ({
    id: n.parentElement?.id ?? "",
    content: n.parentElement?.getAttribute("label") ?? "",
    x: Number(n.querySelector("mxGeometry")?.getAttribute("x")),
    y: Number(n.querySelector("mxGeometry")?.getAttribute("y")),
    w: Number(n.querySelector("mxGeometry")?.getAttribute("width")),
    h: Number(n.querySelector("mxGeometry")?.getAttribute("height")),
    style: n.getAttribute("style"),
  }));

  const links = Array.from(edges).map((l: Element) => ({
    input: l.getAttribute("source") ?? "",
    output: l.getAttribute("target") ?? "",
    style: l.getAttribute("style") ?? "",
  }));

  return {
    nodes,
    links,
  };
};
