import { useCallback, useState } from "react";
import { Menubar } from "primereact/menubar";
import { Dialog } from "primereact/dialog";
import { FileUpload, FileUploadSelectParams } from "primereact/fileupload";
import { useDispatch, useSelector } from "react-redux";
import { selectAttrs, selectMx, selectName } from "../../store/selectors";
import { coverNode } from "../../lib/covernode";
import { decodeMXFile } from "../../lib/decodemxfile";
import readFile from "../../lib/readfile";
import { RightHeader } from "./RightHeader/RightHeader";
import { Button } from "primereact/button";
import { renderAttrs } from "../../lib/renderAttrs";
import { renderMd } from "../../lib/renderMd";
import { renderTable } from "../../lib/renderTable";

// import mx from "../../mxgraph";
// import { mxGraph, mxGraphModel } from "mxgraph";

export const Header = () => {
  const dispatch = useDispatch();
  const mx = useSelector(selectMx);
  const attrs = useSelector(selectAttrs);
  const name = useSelector(selectName);

  /**
   * Dialog for open file
   */
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleShowDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleHideDialogOpen = useCallback(() => {
    setDialogOpen(false);
  }, []);

  /**
   * Загружаем документ с компьютера
   */
  const handleOpenDocument = useCallback(
    async (e: FileUploadSelectParams) => {
      const f = e.files[0];

      try {
        // сохраняем имя загруженного документа
        dispatch({
          type: "SET_NAME",
          payload: f.name.split(".").slice(0, -1).join("."),
        });

        // парсим XML файл диаграммы
        const xmlString = await readFile(f);

        // const doc = mx.xmUtils.parseXml(xmlString);
        // const codec = new mx.mxCodec(doc);

        // const container = document.getElementById('diagram')
        // const graph: mxGraph = new mx.mxGraph(container);
        // const model: mxGraphModel = graph.getModel();

        // codec.decode(doc.documentElement, model);

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(xmlString, "text/xml");
        const mxfile = xmlDOM.documentElement;

        const compressed = mxfile.getAttribute("compressed") !== "false";

        // распаковываем документ, если он упакован
        if (compressed) {
          const diagrams = mxfile.getElementsByTagName("diagram");

          for (let i = 0; i < diagrams.length; i++) {
            const diagram = diagrams[i];
            const diagramContent = decodeMXFile(diagram.textContent || "");
            diagram.innerHTML = diagramContent;
          }
        }

        // cover mxCell with <object>
        const edges = mxfile.querySelectorAll("mxCell[edge='1']");
        const vertexes = mxfile.querySelectorAll("mxCell[vertex='1']");

        edges.forEach(coverNode);
        vertexes.forEach(coverNode);

        // сохраняем DOM диаграммы в стор
        dispatch({ type: "SET_MX", payload: mxfile });

        // считываем параметры атрибутов и сохраняем в стор
        const generatedAttrs = renderAttrs(mxfile);
        const hasSavedAttrs = mxfile.hasAttribute("attrs-options");
        const attrs = hasSavedAttrs
          ? JSON.parse(mxfile.getAttribute("attrs-options") ?? "")
          : generatedAttrs;

        dispatch({ type: "SET_ATTRS", payload: attrs });

        // рендерим Md
        const md = renderMd(mxfile, attrs);

        dispatch({
          type: "SET_MD",
          payload: md,
        });

        // рендерим таблицу
        const table = renderTable(mxfile, attrs);

        dispatch({
          type: "SET_TABLE",
          payload: table,
        });
      } catch (e) {
        console.error("Bad file format");
      }

      setDialogOpen(false);
    },
    [dispatch]
  );

  /**
   * Save document
   */
  const handleSave = useCallback(() => {
    if (mx) {
      mx.setAttribute("attrs-options", JSON.stringify(attrs));
      mx.setAttribute("compressed", "false");
      const uriContent =
        `data:application/octet-stream,` +
        encodeURIComponent(
          `<?xml version="1.0" encoding="UTF-8"?>` + mx.outerHTML
        );

      const downloadLink = document.createElement("a");
      downloadLink.href = uriContent;
      downloadLink.download = `${name}.drawio`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [mx, attrs, name]);

  /**
   * Dialog for info
   */
  const [dialogInfo, setDialogInfo] = useState(false);

  const handleShowDialogInfo = useCallback(() => {
    setDialogInfo(true);
  }, []);

  const handleHideDialogInfo = useCallback(() => {
    setDialogInfo(false);
  }, []);

  /**
   * Main menu
   */
  const menuItems = [
    {
      label: "Открыть",
      icon: "pi pi-fw pi-folder-open",
      command: handleShowDialogOpen,
    },
    {
      label: "Сохранить",
      icon: "pi pi-fw pi-save",
      command: handleSave,
    },
  ];

  return (
    <div className="header">
      <Menubar
        model={menuItems}
        end={<RightHeader onInfoClick={handleShowDialogInfo} />}
      />

      <Dialog
        header="Открыть документ"
        visible={dialogOpen}
        modal
        onHide={handleHideDialogOpen}
      >
        <FileUpload mode="basic" onSelect={handleOpenDocument} />
      </Dialog>

      <Dialog
        header="Diagram Hacker"
        visible={dialogInfo}
        modal
        onHide={handleHideDialogInfo}
        style={{ maxWidth: "600px" }}
      >
        <p style={{ textAlign: "center" }}>
          <i
            className="pi pi-question-circle"
            style={{ fontSize: "1.5em" }}
          ></i>
        </p>
        <p>
          Здесь можно дополнить пользовательскими данными диаграмму{" "}
          <b>drawio</b> и вывести текстовое описание диаграммы в нужном порядке.
        </p>
        <p style={{ textAlign: "center" }}>
          <i
            className="pi pi-exclamation-circle"
            style={{ fontSize: "1.5em" }}
          ></i>
        </p>
        <p>
          Все файлы и данные никуда не отправляются, обрабатываются только в
          браузере.
        </p>
        <p style={{ textAlign: "center" }}>
          <i className="pi pi-envelope" style={{ fontSize: "1.5em" }}></i>
        </p>
        <p>
          Для связи пишите{" "}
          <a href="mailto:info@diagramhacker.ru">info@diagramhacker.ru</a>
        </p>
        <p style={{ textAlign: "center", marginTop: "3rem" }}>
          <Button label="Закрыть" onClick={handleHideDialogInfo} />
        </p>
      </Dialog>
    </div>
  );
};
