import { TStore } from "./types";

export const initialStore: TStore = {
  mx: null,
  expanded: 0,
  md: "",
  attrs: [],
  name: "new",
  table: [],
  textSort: "depth",
  tableSort: "depth",
};

export const reducer = (state: TStore = initialStore, action: any): TStore => {
  switch (action.type) {
    case "SET_MX":
      // console.log("mx", action.payload);
      return {
        ...state,
        mx: action.payload,
      };
    case "SET_EXPANDED":
      return {
        ...state,
        expanded: action.payload,
      };
    case "SET_MD":
      return {
        ...state,
        md: action.payload,
      };
    case "ADD_MD":
      return {
        ...state,
        md: state.md + action.payload,
      };
    case "SET_ATTRS":
      return {
        ...state,
        attrs: action.payload,
      };
    case "SET_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "SET_TABLE":
      return {
        ...state,
        table: action.payload,
      };
    case "SET_TEXTSORT":
      return {
        ...state,
        textSort: action.payload,
      };
    case "SET_TABLESORT":
      return {
        ...state,
        tableSort: action.payload,
      };
    default:
      return state;
  }
};
