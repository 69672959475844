import {
  ATTRS_FORMATS,
  ATTRS_SUBTITLE,
  TAttrs,
  TNode,
  TSort,
} from "../store/types";
import { BooleanFilter } from "./booleanfilter";
import { getNodesByIds } from "./getNodesById";
import { getNodesPathLength } from "./getNodesPathLength";

const HEADER_TOP_LEVEL = 2;
const HEADER_MIN_LEVEL = 6;

/**
 * рендер mx в markdown
 * @param mx DOM диаграммы
 * @param attrs настройки атрибутов
 * @param sortType тип сортировки в глубину/в ширину
 */
export const renderMd = (
  mx: HTMLElement,
  attrs: TAttrs[],
  sortType: TSort = "depth"
) => {
  let render = "";

  const diagrams = mx.getElementsByTagName("diagram");

  Array.from(diagrams).forEach((diagram: Element, index: number) => {
    const name = diagram.getAttribute("name") || undefined;
    const graphModels = diagram.getElementsByTagName("mxGraphModel");

    if (index > 0) {
      render += `

----

`;
    }

    render += `# Диаграмма: ${name}`;

    Array.from(graphModels).forEach((graphModel: Element, key: number) => {
      const root = graphModel.getElementsByTagName("root")[0];
      const vertexes = root.querySelectorAll("mxCell[vertex='1']");

      const rawNodes: TNode[] = Array.from(vertexes)
        .map((vertex: Element, i: number) => {
          const vertexId = vertexes[i].parentElement?.getAttribute("id");

          if (!vertexId) return undefined;

          const vertexSources = Array.from(
            root.querySelectorAll(`mxCell[edge="1"][source="${vertexId}"]`)
          );
          const vertexTargets = Array.from(
            root.querySelectorAll(`mxCell[edge="1"][target="${vertexId}"]`)
          );

          return {
            id: vertexId,
            sourceIds: vertexSources
              .map((source: Element) =>
                source.parentElement?.getAttribute("id")
              )
              .filter(BooleanFilter),
            targetIds: vertexTargets
              .map((source: Element) =>
                source.parentElement?.getAttribute("id")
              )
              .filter(BooleanFilter),
            visited: false,
          };
        })
        .filter(BooleanFilter);

      // filter isolated nodes
      const filteredNodes = rawNodes
        .filter(
          (node) => node?.sourceIds.length !== 0 || node?.targetIds.length !== 0
        )
        .map((node) => ({
          ...node,
          visited: false,
        }));

      // find start nodes
      const startNodes = filteredNodes
        .filter((node) => node.targetIds?.length === 0)
        .sort((n1, n2) => {
          const node1 = mx.querySelector(`object[id='${n1.id}']`);
          const node2 = mx.querySelector(`object[id='${n2.id}']`);

          const order1 = Number(node1?.getAttribute("order"));
          const order2 = Number(node2?.getAttribute("order"));

          return order1 - order2;
        });

      let nodes: TNode[] = [];

      if (sortType === "breadth") {
        /**
         * Обход узлов "в ширину"
         */
        const resultNodes: TNode[] = [];

        startNodes.forEach((startNode) => {
          resultNodes.push({ ...startNode, visited: true });

          const currNodes = getNodesByIds(filteredNodes, startNode.sourceIds);

          while (currNodes.some((n) => !n?.visited)) {
            currNodes.forEach((currNode) => {
              if (!currNode) return;

              if (!resultNodes.includes(currNode)) {
                currNode.level = getNodesPathLength(
                  filteredNodes,
                  startNode.id,
                  currNode.id
                );
                resultNodes.push(currNode);
              }

              currNode.visited = true;

              const addToCurrNodes = getNodesByIds(
                filteredNodes,
                currNode?.sourceIds || []
              ).filter((n) => !n?.visited);
              currNodes.push(...addToCurrNodes);
            });
          }
        });

        nodes = resultNodes;
      } else {
        /**
         * Обход узлов "в грубину"
         */
        const deepNodes: TNode[] = [];

        const walkSourcesIds = (ids: string[], level = HEADER_TOP_LEVEL) => {
          const currNodes = getNodesByIds(filteredNodes, ids).sort((n1, n2) => {
            const node1 = mx.querySelector(`object[id='${n1.id}']`);
            const node2 = mx.querySelector(`object[id='${n2.id}']`);

            const order1 = Number(node1?.getAttribute("order"));
            const order2 = Number(node2?.getAttribute("order"));

            return order1 - order2;
          });

          currNodes.forEach((n, index) => {
            if (n.visited) return;
            deepNodes.push({
              ...n,
              visited: true,
              level,
            });
            filteredNodes.filter((fN) => fN.id === n.id)[0].visited = true;
            walkSourcesIds(n.sourceIds, level + 1);
          });
        };

        startNodes.forEach((startNode, index) => {
          deepNodes.push({
            ...startNode,
            visited: true,
            level: HEADER_TOP_LEVEL,
          });
          startNode.visited = true;

          walkSourcesIds(startNode.sourceIds, HEADER_TOP_LEVEL + 1);
        });

        nodes = deepNodes;
      }

      /**
       * Обходим ноды
       */
      nodes.forEach((n) => {
        const obj = root.querySelector(`object[id='${n.id}']`);

        if (!obj) return;

        // Ищем главный заголовок
        const titleAttr = attrs.filter(
          (attr) => attr.title === ATTRS_FORMATS.title
        )[0];

        if (
          titleAttr &&
          titleAttr.show &&
          (Boolean(obj.getAttribute(titleAttr.name)) || titleAttr.empty)
        ) {
          render += `

${"#".repeat(
  Math.min(n.level ?? HEADER_TOP_LEVEL, HEADER_MIN_LEVEL)
)} ${obj.getAttribute(titleAttr.name)}`;
        }

        // остальные атрибуты
        attrs
          .filter((attr) => attr.title === ATTRS_FORMATS.text)
          .forEach((attr) => {
            if (
              attr.show &&
              (Boolean(obj.getAttribute(attr.name)) || attr.empty)
            ) {
              if (attr.subtitle === ATTRS_SUBTITLE.no) {
                // без подзаголовка
                render += `

${obj.getAttribute(attr.name)}`;
              } else {
                // с подзаголовком
                if (attr.subtitle === ATTRS_SUBTITLE.select) {
                  // подзаголовок из формы, указанный
                  // текст из значения атрибута
                  render += `

### ${attr.text}

${obj.getAttribute(attr.name)}`;
                } else {
                  // подзаголовок из текста, первое предложение
                  render += `

### ${obj.getAttribute(attr.name)?.split(".")[0]}

${obj.getAttribute(attr.name)}`;
                }
              }
            }
          });
      });
    });
  });

  return render;
};
