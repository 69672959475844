import { TStore } from "./types";

export const selectMx = (store: TStore) => store.mx;

export const selectExpanded = (store: TStore) => store.expanded;

export const selectMd = (store: TStore) => store.md;

export const selectAttrs = (store: TStore) => store.attrs;

export const selectName = (store: TStore) => store.name;

export const selectTable = (store: TStore) => store.table;

export const selectTextSort = (store: TStore) => store.textSort;

export const selectTableSort = (store: TStore) => store.tableSort;
