import { TNode } from "../store/types";

/**
 * Возвращает ноду по ид
 *
 * @param nodes массив нод
 * @param nodeId ид искомой ноды
 * @returns массив нод с указанным ид
 */
const getNodeById = (nodes: TNode[], nodeId: string): TNode => {
  return nodes.filter((n) => n.id === nodeId)[0];
};

/**
 * Возвращает ид родительской ноды
 *
 * @param nodes массив нод
 * @param nodeId нода, для которой ищем родителя
 * @returns ид родительской ноды или null, если нод нет или несколько
 */
const getNodeParent = (nodes: TNode[], nodeId: string) => {
  const nodeTargets = getNodeById(nodes, nodeId).targetIds;
  if (nodeTargets.length !== 1) return null;

  const sources = nodes.filter((n) => n.sourceIds.includes(nodeId));

  if (sources.length !== 1) return null;

  return sources[0].id;
};

/**
 * Возвращает расстояние между текущим и стартовым узлом
 *
 * @param nodes массив узлов
 * @param startNodeId стартовый узел
 * @param endNodeId текущий узел
 * @returns длину пути между нодами или undefined, если пути нет
 */
export const getNodesPathLength = (
  nodes: TNode[],
  startNodeId: string,
  endNodeId: string
) => {
  if (startNodeId === endNodeId) return 0;

  let length = 0;
  let parentId = getNodeParent(nodes, endNodeId);

  while (startNodeId !== parentId) {
    if (!parentId) return undefined;
    length++;
    parentId = getNodeParent(nodes, parentId);
  }

  return length;
};
