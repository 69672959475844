import { useRef, useEffect } from "react";
import { Header } from "./components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { selectExpanded } from "./store/selectors";
import { PanelDiagram } from "./components/Panels/PanelDiagram/PanelDiagram";
import { PanelTable } from "./components/Panels/PanelTable/PanelTable";
import { PanelText } from "./components/Panels/PanelText/PanelText";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { decodeMXFile } from "./lib/decodemxfile";
import { coverNode } from "./lib/covernode";
import { renderAttrs } from "./lib/renderAttrs";

import cx from "classnames";

import styles from "./App.module.css";

const DEFAULT_DIAGRAM = "/default.drawio";

const App = () => {
  /**
   *
   */
  const toastRef = useRef(null);
  const dispatch = useDispatch();

  // const mx = useSelector(selectMx);

  useEffect(() => {
    if (toastRef.current) {
      dispatch({ type: "SET_TOAST", payload: toastRef.current });
    }
  }, [dispatch]);

  useEffect(() => {
    // TODO: check localStorage
    if (true) {
      // load default diarram from
      // https://diagramhacker.ru/default.drawio
      fetch(DEFAULT_DIAGRAM, {
        mode: "no-cors",
        cache: "no-cache",
      })
        .then(
          (raw) => raw.text(),
          (e) => console.error("Error loading default diagram:", e)
        )
        .then(
          (text) => {
            const xmlString = String(text);
            const parser = new DOMParser();
            const xmlDOM = parser.parseFromString(xmlString, "text/xml");
            const mxfile = xmlDOM.documentElement;

            const compressed = mxfile.getAttribute("compressed") !== "false";

            // decompress if compressed
            if (compressed) {
              const diagrams = mxfile.getElementsByTagName("diagram");

              for (let i = 0; i < diagrams.length; i++) {
                const diagram = diagrams[i];
                const diagramContent = decodeMXFile(diagram.textContent || "");
                diagram.innerHTML = diagramContent;
              }
            }

            // cover mxCell with <object>
            const edges = mxfile.querySelectorAll("mxCell[edge='1']");
            const vertexes = mxfile.querySelectorAll("mxCell[vertex='1']");

            edges.forEach(coverNode);
            vertexes.forEach(coverNode);

            // save result
            dispatch({ type: "SET_MX", payload: mxfile });

            // render attributes options
            const generatedAttrs = renderAttrs(mxfile);
            const hasSavedAttrs = mxfile.hasAttribute("attrs-options");
            const attrs = hasSavedAttrs
              ? JSON.parse(mxfile.getAttribute("attrs-options") ?? "")
              : generatedAttrs;

            dispatch({ type: "SET_ATTRS", payload: attrs });
          },
          (e) => console.error("Error converting default diagram:", e)
        );
    }
  }, [dispatch]);

  console.log(styles);

  const expanded = useSelector(selectExpanded);

  return (
    <div className={styles.app}>
      <Header />

      <div className={styles.layout}>
        <div
          className={cx(
            styles.column,
            expanded === 0 && styles.column__normal,
            expanded === 1 && styles.column__expanded
          )}
        >
          <PanelDiagram />
        </div>
        <div className={styles.separator}></div>
        <div
          className={cx(
            styles.column,
            expanded === 0 && styles.column__normal,
            expanded === 2 && styles.column__expanded
          )}
        >
          <PanelTable />
        </div>
        <div className={styles.separator}></div>
        <div
          className={cx(
            styles.column,
            expanded === 0 && styles.column__normal,
            expanded === 3 && styles.column__expanded
          )}
        >
          <PanelText />
        </div>
      </div>
    </div>
  );
};

export default App;
