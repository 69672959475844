import pako from "pako";

export const decodeMXFile = (str: string) => {
  const base64Decoded = window.atob(str);
  const inflated = pako.inflateRaw(
    Uint8Array.from(base64Decoded, (c) => c.charCodeAt(0)),
    { to: "string" }
  );
  const uriDecoded = decodeURIComponent(inflated);
  return uriDecoded;
};
